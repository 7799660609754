import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Workouts.module.sass";
import Image from "../Image";
import ScrollParallax from "../ScrollParallax";

const items = [
  "Create Custom Workouts",
  "Follow Your Favorite Influencers",
  "Mirror Popular Workouts",
  "Track your progress easily",
  "Intuitive and clean design",
];

const Workouts = () => {
  return (
    <div className={styles.section}>
      <div className={cn("container", styles.container)}>
        <div className={styles.gallery}>
          <div className={styles.preview}>
            <Image
              srcSet="/images/content/5-5-discover.png"
              srcSetDark="/images/content/5-5-discover.png "
              src="/images/content/5-5-discover.png"
              srcDark="/images/content/5-5-discover.png"
              alt="Phones"
            />
          </div>
        </div>
        <div className={styles.wrap}>
          <h2 className={cn("h2", styles.title)}>
            Your favorite Influencers, <br></br>in your pocket
          </h2>
          <div className={styles.info}>
          Dive into a world where fitness meets inspiration. Find your perfect workout guru today!
          </div>
          <ul className={styles.list}>
            {items.map((x, index) => (
              <li className={styles.item} key={index}>
                {x}
              </li>
            ))}
          </ul>
          <div className={styles.btns}>
            <Link className={cn("button", styles.purpleButton)} to="/download">
              Download
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Workouts;

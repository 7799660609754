import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Intro.module.sass";
import ScrollParallax from "../ScrollParallax";

const Intro = ({ scrollToRef }) => {
  return (
    <div className={styles.section} ref={scrollToRef}>
      <div className={cn("container", styles.container)}>
        <div className={styles.gallery}>
          <ScrollParallax className={styles.preview} animateIn="fadeInUp">
            <img
              srcSet="/images/content/weight.png 2x"
              src="/images/content/bag.png"
              alt="Bag"
            />
          </ScrollParallax>
        </div>
        <div className={styles.wrap}>
          <h2 className={cn("h1", styles.title)}>
          Discover. Mirror. Track. Connect.
          </h2>
          <div className={styles.text}>
            Real-time workouts with top influencers. Your personal training revolution starts now. 🔥🏀
          </div>
          <Link className={cn("button", styles.purpleButton)} to="/download">
            Download
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Intro;

import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Offer.module.sass";

const Offer = ({ className }) => {
  return (
    <div className={cn(className, styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={cn("stage", styles.stage)}>
          Get Started Today
        </div>
        <h2 className={cn("h1", styles.title)}>
          Anywhere you want. Anytime you want.
        </h2>
        <div className={styles.text}>
          Start earning with Gainify today!
        </div>
        <Link className={cn("button", styles.purpleButton)} to="/download">
          Download
        </Link>
      </div>
    </div>
  );
};

export default Offer;

import React from "react";
// import { useParams, useNavigate } from "react-router-dom";

// const isMobile = () => {
//   const userAgent = navigator.userAgent || navigator.vendor || window.opera;
//   return /android|iphone|ipad|ipod/i.test(userAgent);
// };

const MobileRedirect = () => {
  React.useEffect(() => {
    window.location.href = "https://apps.apple.com/us/app/gainify/id6475751265";
    // const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // // Check if user is on an Android device
    // if (/android/i.test(userAgent)) {
    //   window.location.href =
    //     "https://apps.apple.com/us/app/gainify/id6475751265";
    // }
    // // Check if user is on an iOS device
    // else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    //   window.location.href = "https://apps.apple.com/us/app/gainify/id6475751265";
    // }
    // // Fallback for non-Android and non-iOS devices
    // else {
    //   alert(
    //     "Please visit this page on a mobile device to be redirected to the app store."
    //   );
    // }
  }, []);

  return null; // This component does not render anything
};

export default MobileRedirect;

import React from "react";
import cn from "classnames";
import styles from "./Hero.module.sass";
import Subscription from "../../../components/Subscription";
import Image from "../../../components/Image";

const Hero = () => {
  return (
    <div className={styles.hero}>
        <div className={styles.col}>
          <div className={styles.wrap}>
            <div className={cn("stage", styles.stage)}>
              Start earning money from your workouts
            </div>
            <h1 className={cn("h1", styles.title)}>
              Download Gainify Today 
            </h1>
            <div className={styles.text}>
              Join Gainify to transform your fitness influence into financial success.
            </div>
          </div>
        </div>
      </div>
  );
};

export default Hero;

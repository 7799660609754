import React, { useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import cn from "classnames";
import styles from "./Footer.module.sass";
import Subscription from "../Subscription";
import Theme from "../Theme";
import Icon from "../Icon";
import Image from "../Image";

const menu = [
    {
        title: "Lifestyle",
        url: "/lifestyle",
    },
    // {
    //     title: "Pricing",
    //     url: "/pricing",
    // },
    // {
    //     title: "Class",
    //     url: "/class01",
    // },
    {
        title: "Features",
        url: "/features",
    },
    {
        title: "Download",
        url: "/download",
    },
];

const socials = [
    // {
    //     title: "facebook",
    //     size: "16",
    //     url: "https://www.facebook.com/ui8.net/",
    // },
    // {
    //     title: "twitter",
    //     size: "18",
    //     url: "https://twitter.com/ui8",
    // },
    {
        title: "instagram",
        size: "16",
        url: "https://www.instagram.com/ui8net/",
    },
    // {
    //     title: "dribbble",
    //     size: "16",
    //     url: "https://dribbble.com/ui8",
    // },
    // {
    //     title: "behance",
    //     size: "20",
    //     url: "https://www.behance.net/ui8",
    // },
];

const Footer = () => {
    const [visible, setVisible] = useState(false);

    const { pathname } = useLocation();

    return (
        <footer className={styles.footer}>
            <div className={styles.body}>
                <div className={cn("container", styles.container)}>
                    <div className={styles.col}>
                        <div className={styles.box}>
                            <Link className={styles.logo} to="/">
                                <Image
                                    className={styles.pic}
                                    src="/images/gainify-icon.png"
                                    srcDark="/images/gainify-icon.png"
                                    alt="Fitness Pro"
                                />
                            </Link>
                            <Theme className={styles.theme} />
                        </div>
                    </div>
                    <div className={styles.col}>
                        <div className={styles.category}>contact</div>
                        <div className={styles.info}>
                            <p>63 E Center St</p>
                            <p>Provo</p>
                            <p>Utah</p>
                            <p>801-200-4652</p>
                        </div>
                    </div>
                    {/* <div className={styles.col}>
                        <div className={styles.category}>newsletter</div>
                        <div className={styles.info}>
                            Subscribe our newsletter to get more free design
                            course and resource.
                        </div>
                        <Subscription
                            className={styles.subscription}
                            placeholder="Enter your email"
                        />
                    </div> */}
                </div>
            </div>
            <div className={styles.foot}>
                <div className={cn("container", styles.container)}>
                    <div className={styles.copyright}>
                        Copyright © 2024 Gainify LLC. All rights reserved
                    </div>
                    <div className={styles.socials}>
                        {socials.map((x, index) => (
                            <a
                                className={styles.social}
                                href={x.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                key={index}
                            >
                                <Icon name={x.title} size={x.size} />
                            </a>
                        ))}
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;

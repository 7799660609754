import { Routes, Route } from "react-router-dom";
import "./styles/app.sass";
import Page from "./components/Page";
import Home from "./screens/Home";
import Features from "./screens/Features";
import Download from "./screens/Download";
import PrivacyPolicy from "./screens/Privacy Policy";
import MobileRedirect from "./screens/MobileRedirect";

function App() {
  return (
    <Routes>
      <Route path="/">
        <Route
          index
          element={
            <Page>
              <Home />
            </Page>
          }
        />
        <Route
          path="features"
          element={
            <Page>
              <Features />
            </Page>
          }
        />
        <Route
          path="privacy-policy"
          element={
            <Page>
              <PrivacyPolicy />
            </Page>
          }
        />
        <Route
          path="download"
          element={
            <Page>
              <Download />
            </Page>
          }
        />
        <Route path="/workout/:workoutId" element={<MobileRedirect />} />
        <Route path="/user/:userId" element={<MobileRedirect />} />
        <Route path="/group/:groupId" element={<MobileRedirect />} />

        {/* <Route
                    path="class01"
                    element={
                        <Page>
                            <Class01 />
                        </Page>
                    }
                />
                <Route
                    path="class01-details"
                    element={
                        <Page>
                            <Class01Details />
                        </Page>
                    }
                />
                <Route
                    path="class02"
                    element={
                        <Page>
                            <Class02 />
                        </Page>
                    }
                />
                <Route
                    path="class02-details"
                    element={
                        <Page>
                            <Class02Details />
                        </Page>
                    }
                />
                <Route
                    path="lifestyle"
                    element={
                        <Page>
                            <Lifestyle />
                        </Page>
                    }
                />
                <Route
                    path="article"
                    element={
                        <Page>
                            <Article />
                        </Page>
                    }
                /> */}
      </Route>
    </Routes>
  );
}

export default App;

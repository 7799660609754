import React from 'react';

const PrivacyPolicy = () => (
  <div style={{ padding: '40px' }}>
    <h1>Gainify Privacy Policy</h1>
    <p><strong>Effective Date: 02/08/2024</strong></p>
    
    <h2>Welcome to Gainify!</h2>
    <p>We are committed to protecting your privacy and ensuring you have a positive experience on our platform. This Privacy Policy outlines how we collect, use, share, and safeguard your personal information, as well as your rights regarding your information.</p>

    <h2>Information We Collect</h2>
    <ul>
      <li><strong>Personal Identification Information</strong>: Name, email address, physical address, and other contact details.</li>
      <li><strong>Fitness Data</strong>: Workout routines, health metrics, and personal fitness goals.</li>
      <li><strong>Usage Data</strong>: Information on how you use the app, including features accessed and time spent.</li>
      <li><strong>Device and Technical Information</strong>: IP address, device ID, browser type, and operating system.</li>
    </ul>

    <h2>How We Use Your Information</h2>
    <p>- To provide, maintain, and improve our services, including workout tracking and community engagement.</p>
    <p>- To personalize your experience and tailor content based on your preferences and workout history.</p>
    <p>- For communication purposes, such as sending you app updates, fitness tips, and customer service responses.</p>
    <p>- To conduct research and analysis to enhance the app's functionality and user experience.</p>

    <h2>Sharing Your Information</h2>
    <p>We may share your information with:</p>
    <ul>
      <li>Third-party service providers assisting us in delivering our services.</li>
      <li>Partners and affiliates for marketing and promotional purposes, with your consent.</li>
      <li>Law enforcement or other entities when required by law or to protect our rights and safety.</li>
    </ul>

    <h2>Data Security</h2>
    <p>We implement a variety of security measures to maintain the safety of your personal information, including encryption and access controls.</p>

    <h2>Your Rights</h2>
    <p>You have the right to access, correct, delete, or restrict the use of your personal data. You can also object to processing and request data portability.</p>

    <h2>Changes to This Privacy Policy</h2>
    <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>

    <h2>Contact Us</h2>
    <p>If you have any questions about this Privacy Policy, please contact us at 801-200-4652.</p>
  </div>
);

export default PrivacyPolicy;
